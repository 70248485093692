<template>
  <p-form
    @submit="onSubmit"
    v-slot="{ invalid }"
  >
    <!-- Título e subtítulo -->
    <div class="c-row ui-mb-4">
      <div class="c-col">
        <!-- Título -->
        <div class="c-text-h3">
          Cadastre sua nova senha
        </div>

        <!-- Subtítulo -->
        <div class="c-text-b2 c-text-gray">
          *Preenchimento obrigatório
        </div>
      </div>
    </div>

    <!-- Nova senha e Confirmar senha -->
    <div class="c-row">
      <!-- Nova senha -->
      <validation-provider
        class="c-col-full sm:c-col"
        rules="required|min:5"
        vid="password"
        v-slot="{ errors }"
        :custom-messages="{
          required: 'Senha é obrigatória',
          min: 'A senha precisa ter no mínimo 5 caracteres'
        }"
      >
        <input-password
          label="Nova Senha*"
          v-model="form.password"
          hint="Senha com no mínimo 5 caracteres"
          :error-message="errors[0]"
          icon-info="info"
        />
      </validation-provider>

      <!-- Confirmar senha -->
      <validation-provider
        class="c-col-full sm:c-col"
        rules="required|confirmed:password"
        v-slot="{ errors }"
        :custom-messages="{
          required: 'Confirmação de senha é obrigatória',
          confirmed: 'Senhas não são iguais'
        }"
      >
        <input-password
          label="Confirmar nova senha*"
          v-model="form.confirmPassword"
          :error-message="errors[0]"
        />
      </validation-provider>
    </div>

    <separator />

    <!-- Botão Alterar senha -->
    <div class="c-row">
      <div class="c-col-full sm:c-col-1/2">
        <c-button
          type="submit"
          class="ui-w-full"
          :disabled="invalid"
          :loading="isFormSending"
        >
          Cadastrar
        </c-button>
      </div>
    </div>
  </p-form>
</template>

<script>
import { CButton } from '@estrategiahq/coruja-web-ui';
import PForm from '@/components/p-form';
import InputPassword from '@/components/input-password';
import Separator from '@/components/separator';
import { notAuthenticatedError } from '@/utils/errors';
import GenericModal from '@/modals/generic';
import { ValidationProvider } from 'vee-validate';

export default {
  data: () => ({
    form: {
      password: '',
      confirmPassword: '',
    },
    token: '',
    isFormSending: false,
  }),
  components: {
    CButton,
    PForm,
    InputPassword,
    Separator,
    ValidationProvider,
  },
  mounted() {
    this.token = this.$route.query.token;
    if (!this.token) {
      return this.$router.push({ name: 'login' });
    }
  },
  methods: {
    async onSubmit() {
      this.isFormSending = true;
      try {
        await this.$services.accounts.resetPassword(this.token, this.form.password, this.$route.query.id);

        this.$services.events.notifyPasswordRecovered();
        this.showSuccessModal();
      } catch (e) {
        if (e === notAuthenticatedError) {
          this.showInvalidTokenModal();
        } else {
          throw e;
        }
      } finally {
        this.isFormSending = false;
      }
    },
    showSuccessModal() {
      this.$modal.show({
        component: GenericModal,
        props: {
          title: 'A senha foi alterada',
          subTitle: 'Você usará esta nova senha para entrar na Área do aluno e no app.',
          type: 'success',
          confirmButton: {
            label: 'Ir para login',
            onClick: () => {
              this.$modal.hide();
              this.$router.push({ name: 'login', query: { ...this.$route.query } });
            },
          },
        },
      });
    },
    showInvalidTokenModal() {
      this.$modal.show({
        component: GenericModal,
        props: {
          title: 'O link para cadastrar a nova senha não é válido',
          subTitle: 'Para redefinir sua senha você precisa informar seu email novamente.',
          type: 'warning',
          confirmButton: {
            label: 'Informar email',
            onClick: () => {
              this.$modal.hide();
              this.$router.push({ name: 'esqueci-senha', query: { ...this.$route.query } });
            },
          },
        },
      });
    },
  },
};
</script>
